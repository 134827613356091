<template>
	<section class="main-visual">
		<div class="container">
			<div class="main-visual-content">
				<img class="main-visual-content-logo" src="../../assets/img/main/dhfesta_logo-1.png" alt="동행축제" />
				<img class="main-visual-content-model" src="../../assets/img/main/main_visual.png" alt="포방터 쭈꾸미 조윤식, 혜담한복 기말연, 중소벤처기업부 장관 오영주, 옥희방앗간 문지연, 맑고 밝고 따뜻한 협동조합 권택상" />
			</div>
		</div>
		<div class="main-visual-wave">
			<img src="../../assets/img/main/main_wave.png" alt="" />
		</div>
	</section>
</template>
<script>
export default {
	name: 'MainLivePr',
	components: {},
	data: () => ({}),
	computed: {},
	created() {},
	mounted() {},
	methods: {},
};
</script>
