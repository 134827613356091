<template>
	<section class="msection">
		<div class="container">
			<div class="msection-header">
				<div class="flex-left">
					<h2 class="msection-title">실시간 추천제품</h2>
				</div>
				<div class="flex-right">
					<router-link to="/ko/products/category" class="link-more">
						<span class="text">더보기</span>
						<i class="icon"><icon name="icon-arrow-r" /></i>
					</router-link>
				</div>
			</div>

			<div class="msection-content" id="realtimeDibs">
				<swiper ref="swiper" class="swiper" :options="swiperOption" @slideChange="onSwiperChange">
					<swiper-slide v-for="(brcItem, idx) in manprdList" :key="idx" class="realtimeslide">
						<a href="javascript:;"  @click.prevent="handleClickModal(brcItem.id)" class="realtime-item">
							<div class="realtime-content">
								<div class="content-img">
									<img :src="brcItem.image" :alt="brcItem.title" />
								</div>
								<div class="content-info">
									<span class="info-brand">{{ brcItem.company }}</span>
									<span class="info-title">{{ brcItem.title }}</span>
									<div class="info-footer">
										<span class="info-price">{{ Number(brcItem.price).toLocaleString() }}</span>
										<button type="button" class="button-like" @click.stop="setFavr(brcItem)">
											<i class="icon">
												<icon name="icon-heart" v-if="brcItem.like" />
												<icon name="icon-heart-g" v-else />
											</i>
											<span class="sr-only">찜하기</span>
										</button>
									</div>
								</div>
							</div>
						</a>
					</swiper-slide>
				</swiper>
				<swiper-controls :swiper="thisSwiper" :auto="true"></swiper-controls>
			</div>
			<products-modal :visible="visibleModal" @closeModal="visibleModal = false" :item="modalItem" parent-click-evnt-nm="clickEvnt" @clickEvnt="handleClickModalEvnt" />
		</div>
	</section>
</template>
<script>
import Icon from '@/components/common/Icon';
import SwiperControls from '@/components/common/SwiperControls';
import { ACT_GET_CAMPN_MANPRD_RAND_LIST, ACT_UPDATE_CAMPN_MANPRD_FAVR } from '@/store/_act_consts';
import { mapGetters } from 'vuex';
import { getItems, lengthCheck, getCheckItems, setPaging, tryResCallback, queryToValue, isSuccess, dateToDateFormat, timestampToDateFormat} from '@/assets/js/utils';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM, MUT_SHOW_ALERT } from '@/store/_mut_consts';
import { countDDay} from '@/assets/js/DateUtils';
import ProductsModal from '@/components/content/ProductsModal';

export default {
	name: 'MainRealtimeDibs',
	components: { Icon, SwiperControls, ProductsModal },
	data: () => ({
		thisSwiper: null,
		activeIndex: 0,
		modalId: 0, 
		manprdList: [],
		visibleModal: false,
		swiperOption: {
			loop: false,
			slidesPerView: 4,
			spaceBetween: 20,
			autoplay: {
				delay: 3000,
				disableOnInteraction: true,
			},
			navigation: {
				nextEl: '#realtimeDibs .swiper-button-next',
				prevEl: '#realtimeDibs .swiper-button-prev',
			},
			pagination: {
				el: '#realtimeDibs  .swiper-pagination',
				type: 'fraction',
			},
			breakpoints: {
				200: {
					slidesPerView: 1.5,
				},
				680: {
					slidesPerView: 2.5,
				},
				800: {
					slidesPerView: 3,
				},
				1150: {
					slidesPerView: 4,
				},
			},
		},
	}),
	computed: {
    	...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
    	...mapGetters('auth', ['session', 'isAuth']),
		campnId() {
			return this.campn.campnId;
		},
		modalItem() {
			if(this.modalId > 0) return this.manprdList.find(i => i.id == this.modalId) 
			
			return null;
		},
	},
	created() {
		this.getManprdList();
	},
	mounted() {
		this.$nextTick(() => {
			this.thisSwiper = this.$refs.swiper.$swiper;
		});
	},
	methods: {
		onSwiperChange() {
			this.activeIndex = this.thisSwiper.realIndex;
		},
		clickLike(idx) {
			this.manprdList[idx].like = !this.manprdList[idx].like;
		},
		//  F: 찜순, S: 별점순
		getManprdList() {
			this.manprdList = [];
			this.visibleModal = false;

			// api 호출
			this.$store.dispatch(`manprd/${ACT_GET_CAMPN_MANPRD_RAND_LIST}`, {
				campnId: this.campnId,
				randCnt: 10
			})
			.then((res) => {
				if (lengthCheck(res)) {
					this.manprdList  = getCheckItems(res).map((i) => {
							return {
								id: i.manprdId,
								image: i.manprdImgFileUrl,
								company: i.brndNm,
								title: i.manprdNm,
								price: i.manprdPrc,
								rate: i.socreAvg,
								rateCount: i.scoreCnt,
								like: i.favrYn == 'Y',
								likeCount: i.favrCnt,
								myRate: i.myScore,
								desc: i.manprdDesc,
								siteUrl: i.ntslSiteUrl,
								isEng : false,
								useCampnDurYn: i.useCampnDurYn == 'Y',
								ntslStrtDt: timestampToDateFormat(i.ntslStrtDt, 'yyyy-MM-dd'),
								ntslEndDt: timestampToDateFormat(i.ntslEndDt, 'yyyy-MM-dd')
							}
						});
				} 
			})
			.catch((e) => {
				console.error(e);
			});
		},
		chkLogin(title) {
			if (typeof this.session.userId == 'undefined') {
				const param = {
					title: title,
					html: true,
					showCancelButton: false,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {},
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
				return false;
			}

			return true;
		},
		chkCampnDuration() {

			const nowDt = dateToDateFormat(new Date(), 'yyyy-MM-dd') + ' 00:00';
			// 기간 체크
			if (countDDay(this.campn.strtDt, nowDt) < 0 || countDDay(nowDt, this.campn.endDt) < 0) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: `이벤트 기간이 아닙니다.`,
					rightNow: true
				});
				return false;
			}
			return true;
		}, 
		// 찜하기 설정 
		setFavr(item) {
			if (!this.chkCampnDuration()) return false;
			if (!this.chkLogin('로그인 상태에서 찜할 수 있습니다. 로그인하시겠습니까?')) return false;

			// api 호출
			this.$store
				.dispatch(`manprd/${ACT_UPDATE_CAMPN_MANPRD_FAVR}`, {
					manprdId: item.id,
					params: {
						manprdId: item.id,
						score: 0,
						useYn: !item.like ? 'Y' : 'N',
					},
				})
				.then((res) => {
					this.getFavrAlter(item, res);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		handleClickModal(idx, type) {
			this.modalId = idx;
			this.visibleModal = true;
		},
		handleClickModalEvnt(type, score) {
			this.getManprdList();
		},
		getFavrAlter(item, res) {
			if (isSuccess(res)) {
				item.like = !item.like;
				let text = item.like ? '찜하기 등록되었습니다' : '찜하기 해제되었습니다.';
				//this.$toast.open(text);

				// 마케팅 동의 여부 체크
				if(item.like && this.campn.prvcMktAgreYn != 'Y') {
					this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
						title: '찜하기 등록되었습니다. 찜하기 이벤트에 참여하시겠습니까?',
						showCancelButton: true,
						yesfunc: () => {this.$router.push({name:'EventDibs'});},
						nofunc: () => {this.getManprdList();}
					});
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: text,
						yesfunc: () => {},
						rightNow: true,
					});
					this.getManprdList();
				}
			} else {
				let text = !item.like ? '찜하기 등록 실패 하였습니다.' : '찜하기 해제 실패 하였습니다.';
				if(res && res.result && res.result.number > 900) text = res.result.message;
				
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: text,
					yesfunc: () => {},
					rightNow: true
				});
			}
		},
	},
};
</script>
