<template>
	<section class="msection">
		<div class="container">
			<div class="fp-content">
				<div class="msection-title">
					<span class="title-large">9월</span>
					<span class="title-small">동행축제</span>
				</div>
				<p class="msection-text">
					내수 활성화를 위해 전국적 대규모 소비축제인 대한민국 동행축제를 개최하여 <br />
					소비부담 완화 및 중소ㆍ소상공인 매출 증대
				</p>
			</div>

			<div class="fp-content">
				<div class="event-informs">
					<div class="event-info">
						<div class="event-info-icon">
							<img src="../../assets/img/main/event_icon01.png" alt="행사기간" />
						</div>
						<div class="event-info-desc">
							<span class="desc-title">행사 기간</span>
							<div class="desc-date">
								<span>2024년 08월 28일 부터 ~</span>
								<span>2024년 09월 28일 까지</span>
							</div>
						</div>
					</div>
					<div class="event-info">
						<div class="event-info-icon">
							<img src="../../assets/img/main/event_icon02.png" alt="행사내용" />
						</div>
						<div class="event-info-desc">
							<span class="desc-title">행사 내용</span>
							<p class="desc-detail">온라인할인전, 라이브커머스, TV홈쇼핑, 지역축제 연계 판매전, 소비촉진 이벤트</p>
						</div>
					</div>
				</div>
			</div>
			<div class="event-youtube-container">
				<!-- <div class="youtube-wrap"><iframe src="https://www.youtube.com/embed/uo_KZYwhh3I?si=Rl9N0LlC5vDSw_jx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen=""></iframe></div> -->
				<div class="youtube-wrap">
					<iframe :src="srcUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen=""> </iframe>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: 'MainInfoVideo',
	data: () => ({}),
	computed: {
		srcUrl() {
			const videoId = 'Q4nEsr5RUiY';
			return `https://www.youtube.com/embed/${videoId}?si=Rl9N0LlC5vDSw_jx&playlist=${videoId}&loop=1`;
		},
	},
	created() {},
	beforeDestroy() {},
	mounted() {},
	methods: {},
};
</script>
