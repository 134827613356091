<template>
	<section class="msection">
		<div class="container">
			<div class="msection-header">
				<div class="flex-left">
					<h2 class="msection-title">동행축제 이야기</h2>
				</div>
				<div class="flex-right"></div>
			</div>
		</div>
		<div class="msection-content" id="festaShow">
			<div class="swiper-festa-container">
				<swiper ref="swiperFesta" class="swiper" :options="swiperOption" @slideChange="onSwiperChange">
					<swiper-slide v-for="(item, idx) in festaList" :key="idx">
						<div class="festa-item">
							<span class="festa-content__img">
								<img :src="require(`@/assets/img/main/${item.imgFileNm}`)" :alt="item.title" />
							</span>
							<span class="festa-content__right">
								<strong class="festa-content__title">{{ item.title }}</strong>
								<span class="festa-content__text">
									<em class="festa-content__desc" v-html="item.desc"></em>
									<span class="festa-content__slogan" v-if="item.slogan !== ''">
										<span class="text" v-html="item.slogan"></span>
									</span>
									<a v-if="item.url" :href="item.url" target="_blank" class="button is-large is-secondary is-rounded" title="새창열림">바로가기</a>
								</span>
							</span>
						</div>
						<div class="festa-mobile">
							<span class="festa-mobile__date">{{ item.pgDate }}</span>
							<span class="festa-mobile__title">{{ item.pgTitle }}</span>
						</div>
					</swiper-slide>
				</swiper>
				<div class="swiper-buttons-container">
					<div class="swiper-button-prev" slot="button-prev">
						<i class="icon"><icon name="icon-swiper-arrow"></icon></i>
					</div>
					<div class="swiper-button-next" slot="button-next">
						<i class="icon"><icon name="icon-swiper-arrow"></icon></i>
					</div>
				</div>
			</div>

			<div class="festa-controls">
				<button type="button" class="festa-controls-item" :class="{ 'is-active': index === activeIndex }" v-for="(item, index) in festaPagiList" :key="index" @click.prevent="gotoSwiper(index + 2)">
					<em>{{ item.date }}</em>
					<em>{{ item.title }}</em>
				</button>
			</div>

			<!-- 20240516 : 표준 swiper control -->
			<div class="festa-swiper-controls">
				<swiper-controls :swiper="thisSwiper" :auto="true"></swiper-controls>
			</div>
		</div>
	</section>
</template>
<script>
import Icon from '@/components/common/Icon';
import SwiperControls from '@/components/common/SwiperControls';
export default {
	name: 'MainFestaShow',
	components: { Icon, SwiperControls },
	data: () => ({
		thisSwiper: null,
		activeIndex: 0,
		swiperOption: {
			loop: true,
			// effect: 'fade',
			initialSlide: 4,
			loopedSlides: 2,
			// notNextTick: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			},
			slidesPerView: 1,
			// loopedSlides: 0,
			spaceBetween: 0,
			touchRatio: 0.2,
			navigation: {
				nextEl: '#festaShow .swiper-button-next',
				prevEl: '#festaShow .swiper-button-prev',
			},
			pagination: {
				el: '#festaShow  .swiper-pagination',
				type: 'fraction',
			},
			breakpoints: {
				200: {
					spaceBetween: 15,
					slidesPerView: 1.25,
					centeredSlides: true,
				},
				801: {
					spaceBetween: 0,
					slidesPerView: 1,
				},
			},
		},

		festaPagiList: [
			{
				date: '2022.09.01 ~ 7',
				title: '7일간의 동행축제',
			},
			{
				date: '2022.12.16 ~ 25',
				title: '한겨울의 동행축제 윈·윈터 페스티벌',
			},
			{
				date: '2023.05.01 ~ 28',
				title: '5월 봄빛 동행축제',
			},
			{
				date: '2023.08.30 ~ 10.08',
				title: '9월 황금녘 동행축제',
			},
			{
				date: '2023.12.04 ~ 31',
				title: '12월 눈꽃 동행축제',
			},
			{
				date: '2024.05.01 ~ 28',
				title: '2024년 5월 동행축제',
			},
			{
				date: '2024.08.28 ~ 09.28',
				title: '9월 동행축제',
			},
		],
		festaList: [
			{
				pgDate: '2022.09.01 ~ 7',
				pgTitle: '7일간의 동행축제',
				imgFileNm: 'festa4.png',
				bgFileNm: 'bg_festa4.jpg',
				title: '7일간의 동행축제',
				desc: '동행세일을 확대하여 다양한 국민 참여형 행사를 대규모로 개최하고, 온 국민이 참여하고 즐길 수 있는 상생 문화 캠페인으로 발돋움',
				slogan: '‘온 국민이 참여하는 축제의 장’',
			},
			{
				pgDate: '2022.12.16 ~ 25',
				pgTitle: '한겨울의 동행축제 윈·윈터 페스티벌',
				imgFileNm: 'festa3.png',
				bgFileNm: 'bg_festa3.jpg',
				title: '한 겨울의 동행축제 윈·윈터 페스티벌',
				desc: '소비자와 중소·소상공인, 유통사를 비롯해 도움의 손길이 필요한 분들이 겨울철(Winter) 함께 행복을 누릴 수(Win) 있도록 추진하는 따뜻한 ‘겨울 동행축제’',
				slogan: '‘모두가 이득 행복이 가득’',
			},
			{
				pgDate: '2023.05.01 ~ 28',
				pgTitle: '5월 봄빛 동행축제',
				imgFileNm: 'festa2.png',
				bgFileNm: 'bg_festa2.jpg',
				title: '5월 봄빛 동행축제',
				desc: '가정의 달과 중소기업 주간의 의미를 살려 국민과 기업 모두가 참여하는 교류·화합 축제의 장. 지역경제 활성화를 위해 지자체와 함께하는 전국적인 행사',
				slogan: '‘전국 방방곡곡 경기진작을 위한 온 국민,<br> 힘 모으기! 캠페인’',
			},
			{
				pgDate: '2023.08.30 ~ 10.08',
				pgTitle: '9월 황금녘 동행축제',
				imgFileNm: 'festa1.png',
				bgFileNm: 'bg_festa1.jpg',
				title: '9월 황금녘 동행축제',
				desc: '추석 명절에 맞춰 감사의 마음을 전하고 서로 응원하며 경제주체들의 기(氣)를 살리는 캠페인. 온국민이 참여해 전국 지역 경제에 활력 불어넣기!',
				slogan: '‘온 국민이 힘을 모아 우리 경제 기(氣)살리기 캠페인’',
			},
			{
				pgDate: '2023.12.04 ~ 31',
				pgTitle: '12월 눈꽃 동행축제',
				imgFileNm: 'festa5.png',
				bgFileNm: 'bg_festa5.jpg',
				title: '12월 눈꽃 동행축제',
				desc: '따뜻한 나눔과 함께 전국민 상생 소비 캠페인을 이어가는 2023년 마지막 동행축제,<br> 눈꽃 동행축제.',
				slogan: '온 국민, 힘 모아 온기(溫氣) 나눔',
			},
			{
				pgDate: '2024.05.01 ~ 28',
				pgTitle: '2024년 5월 동행축제',
				imgFileNm: 'festa6.png',
				bgFileNm: 'bg_festa6.jpg',
				title: '2024년 5월 동행축제',
				desc: '가족과 함께 고마운 분에게 마음을 전하고, <br>전국 중소기업•소상공인에게 활력을 불어넣는 동행축제',
				slogan: '‘살맛나는 행복쇼핑, 2024 동행축제’',
				// url: 'http://k-shoppingfesta.org',
			},
			{
				pgDate: '2024.08.28 ~ 09.28',
				pgTitle: '2024년 9월 동행축제',
				imgFileNm: 'festa7.jpg',
				bgFileNm: 'bg_festa6.jpg',
				title: '2024년 9월 동행축제',
				desc: '내수 활성화를 위해 전국적 대규모 소비축제인 대한민국 동행축제를 개최하여 소비부담 완화 및 중소ㆍ소상공인 매출 증대',
				slogan: '2024.08.28 ~ 09.28',
			},
		], //상단 프로그램 배너 목록
	}),
	computed: {},
	created() {},
	mounted() {
		this.$nextTick(() => {
			// document.querySelector('#festaShow ').classList.add('fp-swiper-fade');
			this.thisSwiper = this.$refs.swiperFesta.$swiper;
			// setTimeout(() => {
			// 	this.thisSwiper.slidePrev();
			// }, 100);
			// document.querySelectorAll('.festa-controls-item').forEach((item, idx) => {
			// 	document.querySelectorAll('.festa-controls-item')[3].addEventListener('click', () => {
			// 	});
			// });
			// document.querySelectorAll('.festa-controls-item').addEventListener('click', () => {
			// 	this.thisSwiper.slidePrev();
			// });
			// this.thisSwiper.slideTo(5);
		});
	},
	methods: {
		onSwiperChange() {
			this.activeIndex = this.thisSwiper.realIndex;
			// 20231127 : 동행축제 수정
			// Object.keys(this.thisSwiper.slides).forEach((key) => {
			// 	try {
			// 		this.thisSwiper.slides[key].classList.remove('swiper-slide-custom-active');
			// 	} catch (error) {}
			// });
			// setTimeout(function () {
			// 	Object.keys(this.thisSwiper.slides).forEach((key) => {
			// 		try {
			// 			if (this.thisSwiper.slides[key].classList.contains('swiper-slide-active')) {
			// 				this.thisSwiper.slides[key].classList.add('swiper-slide-custom-active');
			// 			}
			// 		} catch (error) {}
			// 	});
			// }, 1300);
			// document.querySelector('#festaShow ').classList.remove('__active-fp');
			// let timerId;
			// clearTimeout(timerId);
			// timerId = setTimeout(function () {
			// 	document.querySelector('#festaShow ').classList.add('__active-fp');
			// }, 1000);
		},
		gotoSwiper(idx) {
			this.thisSwiper.slideTo(idx);
		},
	},
};
</script>
