<template>
	<main id="container">
		<!-- 메인 비주얼 -->
		<main-visual />

		<div class="msection-wrap">
			<!-- 황금녘 동행축제 안내 및 영상  -->
			<main-info-video />

			<!-- 동행축제 이야기 -->
			<main-festa-show />

			<!-- 동행축제 실시간 찜하기 -->
			<main-realtime-dibs />

			<!-- 상생 할인전 -->
			<main-winwin-exhibition />
		</div>
	</main>
</template>
<script>
import { mapGetters } from 'vuex';
import MainVisual from '@/components/main/MainVisual';
import MainInfoVideo from '@/components/main/MainInfoVideo';
import MainFestaShow from '@/components/main/MainFestaShow';
import MainRealtimeDibs from '@/components/main/MainRealtimeDibs';
import MainWinwinExhibition from '@/components/main/MainWinwinExhibition';

export default {
	name: 'Main',
	components: { MainVisual, MainInfoVideo, MainFestaShow, MainRealtimeDibs, MainWinwinExhibition },
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile', 'returnRoute']),
	},
	watch: {},
	props: {},
	data: () => ({}),
	created() {},
	beforeDestroy() {},
	mounted() {},
	methods: {},
	destroyed() {},
};
</script>
