<template>
	<section class="msection" v-if="items.length > 0">
		<div class="container" >
			<div class="msection-header msection-header__winwin">
				<div class="flex-left">
					<h2 class="msection-title">상생 할인전</h2>
					<p class="msection-header-desc">놓치면 안되는 주요 할인전</p>
				</div>
				<div class="flex-right">
					<router-link to="/ko/exhibitionList" class="link-more">
						<span class="text">더보기</span>
						<i class="icon"><icon name="icon-arrow-r" /></i>
					</router-link>
				</div>
			</div>
			<div class="msection-content" id="winwinExhibition">
				<swiper ref="swiper" class="swiper" :options="swiperOption" @slideChange="onSwiperChange">
					<swiper-slide v-for="(item, idx) in items" :key="idx" class="exh-slide">
						<div class="exhibition-content">
							<div class="exhibition-content-logo">
								<img :src="item.tmpexbImgUrl" :alt="item.tmpexbImgNm" />
							</div>
							<div class="exhibition-content-disc" :style="`background-image: ${item.bgImg}`">
								<div class="exhibition-content-text">
									<span class="content-disc-label">{{ item.tmpexbPfxCn }}</span>
									<span class="content-disc-promo">{{ item.tmpexbBodysCn }}</span>
								</div>
								<div class="content-disc-img">
									<img src="../../assets/img/main/exclude_img.png" alt="" />
								</div>
							</div>
							<div class="exhibition-content-info">
								<span class="content-info-title">{{ item.tmpexbNm }}</span>
								<span class="content-info-date">{{ item.period }}</span>
								<a :href="item.platformUrl" target="_blank" class="content-info-button">바로가기</a>
							</div>
						</div>
					</swiper-slide>
				</swiper>
			</div>
		</div>
	</section>
</template>
<script>
import Icon from '@/components/common/Icon';
import { ACT_GET_TMPEXB_LIST } from '../../store/_act_consts';
import { getCheckItems, dateToDateFormat } from '../../assets/js/utils';
import { mapGetters } from 'vuex';

export default {
	name: 'MainWinwinExhibition',
	components: { Icon },
	data: () => ({
		thisSwiper: null,
		activeIndex: 0,
		campnId: 0,
		tmpexbClsDcd: 0,
		tmpexbClsDcdList: [],
		items: [],
		itemCount: 2,
		itemBackColor: 3,
		swiperOption: {
			loop: false,
			slidesPerView: 3,
			spaceBetween: 20,
			pagination: {
				el: '#winwinExhibition .swiper-pagination',
				type: 'fraction',
			},
			navigation: {
				nextEl: '#winwinExhibition .swiper-button-next',
				prevEl: '#winwinExhibition .swiper-button-prev',
			},
			breakpoints: {
				200: {
					slidesPerView: 1.2,
				},
				700: {
					slidesPerView: 2.5,
				},
				801: {
					slidesPerView: 3,
				},
			},
		},
	}),
	computed: {
		...mapGetters('common', ['commonCode', 'campn']),
		swiper() {
			return this.$refs.mySwiper.$swiper;
		},
		dateConvert() {
			return (item, format) => {
				return dateToDateFormat(new Date(item), format);
			};
		},
		dateString() {
			return (item) => {
				return ('0' + (new Date(item).getMonth() + 1)).slice(-2) + '.' + ('0' + new Date(item).getDate()).slice(-2);
			};
		},
	},
	created() {
		this.getTmpexbList();
	},
	mounted() {
		this.$nextTick(() => {
			if(this.items.length > 0)this.thisSwiper = this.$refs.swiper.$swiper;
		});
	},
	methods: {
		onSwiperChange() {
			this.activeIndex = this.thisSwiper.realIndex;
		},
		getTmpexbList() {
			this.$store.dispatch(`exhibition/${ACT_GET_TMPEXB_LIST}`, { campnId: this.campn.campnId, tmpexbClsDcd: this.tmpexbClsDcd, mainExpsrYn: 'Y' }).then((res) => {
				this.items = getCheckItems(res);
				this.setParam();
				this.items = this.items.filter((x) => !x.closed).filter((x, idx) => idx <= this.itemCount);
			});
		},
		setParam() {
			this.items.forEach((item, idx) => {
				const itemStrtDt = this.dateConvert(item.strtDt, 'yyyyMMdd');
				const itemEndDt = this.dateConvert(item.endDt, 'yyyyMMdd');
				const nowDate = this.dateConvert(new Date(), 'yyyyMMdd');
				item.period = this.dateString(item.strtDt) + ' ~ ' + this.dateString(item.endDt);
				item.closed = itemStrtDt <= nowDate && itemEndDt >= nowDate ? false : true;
				item.bgImg = this.setBgImg(idx);
			});
		},
		setBgImg(idx) {
			switch (idx % this.itemBackColor) {
				case 0:
					return 'linear-gradient(0deg,rgba(232,168,44,1), rgba(232,168,44,0.5))';
				case 1:
					return 'linear-gradient(0deg,rgba(240,90,34,1), rgba(240,90,34,0.5))';
				case 2:
					return 'linear-gradient(0deg,rgba(255,115,0,1), rgba(255,115,0,0.5))';
			}
		},
	},
};
</script>
